//@ts-check
import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/Layout'

import favicon from '../assets/images/favicon.ico';
import styles from "./blog-post.module.css"
import { tagManager } from '../scripts'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')

    const text = post.body.childMarkdownRemark.html;
    let description = text.replace("<p>", "");
    description = description.replace("</p>", "");
    description = description.replace("<em>", "");
    description = description.replace("</em>", "");

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={`${post.title} | ScreenFox`} >
            <meta name="description" content={description} />

            <html lang="es" />
            <link rel="alternate" href={`https://getscreenfox.com${this.props.location.pathname}`} hrefLang="es" />

            <link rel="icon" href={favicon} type="image/x-icon" />

            <meta property="og:title" content={`${post.title} | ScreenFox`} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={`${post.heroImage.file.url}`} />
            
            <script>{tagManager}</script>
          </Helmet>

          <div className={`container ${styles.body} ${styles.withPadding}`}>
            <h1 className={`${styles.title}`}>{post.title}</h1>
            <p
              style={{
                display: 'block',
              }}
            >
              {post.publishDate}
            </p>

            <div className={`${styles.hero}`}>
              <Img className={styles.heroImage} alt={post.title} sizes={post.heroImage.fluid} />
            </div>

            <div
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 650) {
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          url
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
